import './App.css';
import githubsvg from './svg/github.svg';
import imdbsvg from './svg/imdb.svg';
import linkedinsvg from './svg/linkedin.svg';


function App() {
  return (
    <div className="card">
      <p className="name">THAI BACH</p>
      <div className="icons-container">
        <span className="icon">
          <a href="https://uk.linkedin.com/in/thaibach" target="_blank" rel="noopener noreferrer nofollow">
            <img id="linked" src={linkedinsvg} alt="linkedin logo"/>
          </a>
        </span>
        <span className="icon">
          <a href="https://www.imdb.com/name/nm4128602/" target="_blank" rel="noopener noreferrer nofollow">
            <img id="imdb" src={imdbsvg} alt="imdb logo"/>
          </a>
        </span>
        <span className="icon" />
        <span className="icon">
          <a href="https://github.com/thbach" target="_blank" rel="noopener noreferrer nofollow">
            <img id="github" src={githubsvg} alt="github logo"/>
          </a>
        </span>
      </div>
    </div>
  );
}

export default App;
